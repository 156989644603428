/**
 * Add authentication token to header if the user is logged in
 *
 * @returns {Object} Authorization token
 */
export default function authHeader () {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token }
  } else {
    return {}
  }
}
